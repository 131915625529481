"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LEVELS = exports.SHADOW = exports.BLOCK = exports.EMPTY = exports.GAME_SIZE_WEIGHT = exports.GAME_SIZE_HEIGHT = void 0;
exports.GAME_SIZE_HEIGHT = 20;
exports.GAME_SIZE_WEIGHT = 10;
// export const BRICK_SIZE = 4;
exports.EMPTY = 0;
exports.BLOCK = 10;
exports.SHADOW = 11;
// export const BRICK = 1;
exports.LEVELS = {
    '1': 0.01667,
    '2': 0.021017,
    '3': 0.026977,
    '4': 0.035256,
    '5': 0.04693,
    '6': 0.06361,
    '7': 0.0879,
    '8': 0.1236,
    '9': 0.1775,
    '10': 0.2598,
    '11': 0.388,
    '12': 0.59,
    '13': 0.92,
    '14': 1.46,
    '15': 2.36,
};
