"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const constants_1 = require("./constants");
const tetro_1 = require("./tetro");
const createElem = (column, i) => {
    const elem = document.createElement('div');
    // elem.textContent = i.toString()
    // elem.textContent = column.toString()
    switch (column) {
        case (constants_1.BLOCK): {
            elem.classList.add('brick-box');
            break;
        }
        case (constants_1.EMPTY): {
            elem.classList.add('cell');
            break;
        }
        case (constants_1.SHADOW): {
            elem.classList.add('shadow');
            break;
        }
        default: {
            elem.classList.add('brick', `brick-color-${column}`);
            break;
        }
    }
    return elem;
};
const render = (state, brick) => {
    // console.log('render')
    const gameFrame = (0, tetro_1.clearGame)();
    state.game.forEach((r, i) => r.forEach((c, j) => gameFrame[i][j] = c));
    // render shadow
    const vBrick = (0, tetro_1.validBrick)(brick);
    [...Array(constants_1.GAME_SIZE_HEIGHT).keys()].every((p) => {
        const l = vBrick.some((r, i) => {
            return r.some((c, j) => {
                if ([constants_1.EMPTY, constants_1.BLOCK].indexOf(c) != -1) {
                    return false;
                }
                else {
                    if ((i + p) < constants_1.GAME_SIZE_HEIGHT)
                        return (0, tetro_1.isBreak)(state.game[i + p][j + state.y]) == (0, tetro_1.isBreak)(c) && (p > state.x);
                    return true;
                }
            });
        });
        let s = p - 1;
        if (!l && (p == (constants_1.GAME_SIZE_HEIGHT - 1))) {
            s = (19 - vBrick.length + 1);
        }
        if (l || (p == (constants_1.GAME_SIZE_HEIGHT - 1))) {
            vBrick.forEach((r, i) => {
                r.forEach((c, j) => {
                    if ((i + s) < constants_1.GAME_SIZE_HEIGHT) {
                        gameFrame[i + s][j + state.y] = [constants_1.EMPTY, constants_1.BLOCK, constants_1.SHADOW].indexOf(c) == -1 ? constants_1.SHADOW : gameFrame[i + s][j + state.y];
                    }
                });
            });
        }
        return !l;
    });
    vBrick.forEach((r, i) => {
        r.forEach((c, j) => {
            if ((i + state.x) < constants_1.GAME_SIZE_HEIGHT) {
                gameFrame[i + state.x][j + state.y] = (0, tetro_1.updatePosition)(gameFrame[i + state.x][j + state.y], c);
            }
        });
    });
    const rootElem = document.getElementById('tetro');
    rootElem.innerHTML = '';
    (0, tetro_1.validGame)(gameFrame).forEach((r, i) => {
        const rowContainer = document.createElement('div');
        rowContainer.classList.add('row');
        r.forEach(c => rowContainer.appendChild(createElem(c, i)));
        rootElem.appendChild(rowContainer);
    });
};
exports.render = render;
