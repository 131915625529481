"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetKey = exports.handleKeyPress = exports.leftOffset = void 0;
const constants_1 = require("./constants");
const tetro_1 = require("./tetro");
// const xOffset = (brick: Brick, columnIndex: number) => brick.figure.every(e => e[columnIndex] === 0) ? 1 : 0;
const leftOffset = (brick) => {
    let columnIndex = 0;
    while (brick.every(e => [constants_1.EMPTY, constants_1.BLOCK].indexOf(e[columnIndex]) != -1)) {
        columnIndex += 1;
    }
    return columnIndex;
};
exports.leftOffset = leftOffset;
const rightOffset = (brick) => {
    let columnIndex = 0;
    let figureReversed = [...brick].map((r) => [...r].reverse());
    while (figureReversed.every(e => [constants_1.EMPTY, constants_1.BLOCK].indexOf(e[columnIndex]) != -1)) {
        columnIndex += 1;
    }
    return columnIndex;
};
const handleKeyPress = (state, brick, key) => {
    // console.log(
    //     'validBrick(brick).length)', validBrick(brick)
    // )
    if (['ArrowDown', 'KeyS'].indexOf(key.code) != -1) {
        if ((state.x + (0, tetro_1.validBrick)(brick).length) < constants_1.GAME_SIZE_HEIGHT)
            state.x += 1;
    }
    // console.log(
    //     state.y,
    //     leftOffset(brick),
    //     rightOffset(brick)
    // )
    if ((['ArrowLeft', 'KeyA'].indexOf(key.code) != -1) && (state.y > 0 - (0, exports.leftOffset)(brick))) {
        let canLeft = true;
        (0, tetro_1.validBrick)(brick).forEach((r, i) => r.forEach((c, j) => {
            if (([constants_1.EMPTY, constants_1.BLOCK].indexOf(c) == -1) && ((state.x + i) < state.game.length))
                if ([constants_1.EMPTY, constants_1.BLOCK, undefined].indexOf(state.game[state.x + i][state.y + j - 1]) == -1) {
                    canLeft = false;
                }
        }));
        if (canLeft) {
            state.y -= 1;
        }
    }
    else if ((['ArrowRight', 'KeyD'].indexOf(key.code) != -1) && (state.y < constants_1.GAME_SIZE_WEIGHT - (brick.length - rightOffset(brick)))) {
        let canRight = true;
        (0, tetro_1.validBrick)(brick).forEach((r, i) => r.forEach((c, j) => {
            if (([constants_1.EMPTY, constants_1.BLOCK].indexOf(c) == -1) && ((state.x + i) < state.game.length))
                if ([constants_1.EMPTY, constants_1.BLOCK, undefined].indexOf(state.game[state.x + i][state.y + j + 1]) == -1) {
                    canRight = false;
                }
        }));
        if (canRight) {
            state.y += 1;
        }
    }
    return state;
};
exports.handleKeyPress = handleKeyPress;
const resetKey = (key) => key.code = undefined;
exports.resetKey = resetKey;
