"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rotate = exports.emptyBrick = void 0;
const constants_1 = require("./constants");
const tetro_1 = require("@games/tetro/src/lib/tetro");
const rightOffsetAfterRotation = (state, brick, rotatedBrick) => {
    return state.y + rotatedBrick.length > constants_1.GAME_SIZE_WEIGHT ? (state.y + rotatedBrick.length - constants_1.GAME_SIZE_WEIGHT) : 0;
};
const leftOffsetAfterRotation = (game) => {
    return game.y < 0 ? 1 : 0;
};
const emptyBrick = (brick) => Array(brick.length).fill(constants_1.EMPTY).map(e => Array(brick.length).fill(constants_1.EMPTY));
exports.emptyBrick = emptyBrick;
const rotateBrick = (state, brick, rotatedBrick) => {
    brick.forEach((r, i) => r.forEach((c, j) => {
        rotatedBrick[j][brick[0].length - 1 - i] = c;
    }));
    state.y -= rightOffsetAfterRotation(state, brick, rotatedBrick);
    state.y += leftOffsetAfterRotation(state);
    while (state.y < 0) {
        state.y += leftOffsetAfterRotation(state);
    }
    let canRotate = true;
    console.log({ canRotate });
    (0, tetro_1.validBrick)(rotatedBrick).forEach((r, i) => r.forEach((c, j) => {
        if ([constants_1.EMPTY, constants_1.BLOCK].indexOf(c) == -1) {
            if ((state.x + i) < state.game.length) {
                if ([constants_1.EMPTY, constants_1.BLOCK, undefined].indexOf(state.game[state.x + i][state.y + j]) == -1) {
                    // вылазиет за правую границу
                    canRotate = false;
                }
            }
            else {
                // вылазиет за нижнюю границу
                canRotate = false;
            }
        }
    }));
    return [state, canRotate ? rotatedBrick : brick];
};
const rotate = (state, brick, key) => ['ArrowUp', 'KeyW'].indexOf(key.code) != -1 ? [true, ...rotateBrick(state, brick, (0, exports.emptyBrick)(brick))] : [false, state, brick];
exports.rotate = rotate;
