"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.score = exports.validBrick = exports.validGame = exports.updatePosition = exports.clearGame = exports.randomBrick = exports.isBreak = exports.brickWidth = void 0;
const constants_1 = require("./constants");
const rotation_1 = require("./rotation");
const COLOR1 = 1;
const COLOR2 = 2;
const COLOR3 = 3;
const COLOR4 = 4;
const COLOR5 = 5;
const COLOR6 = 6;
const COLOR7 = 7;
const bricks = [
    [
        [constants_1.BLOCK, constants_1.BLOCK, constants_1.BLOCK, constants_1.BLOCK],
        [COLOR1, COLOR1, COLOR1, COLOR1],
        [constants_1.BLOCK, constants_1.BLOCK, constants_1.BLOCK, constants_1.BLOCK],
        [constants_1.BLOCK, constants_1.BLOCK, constants_1.BLOCK, constants_1.BLOCK],
    ],
    [
        [COLOR2, COLOR2, COLOR2],
        [constants_1.BLOCK, constants_1.BLOCK, COLOR2],
        [constants_1.BLOCK, constants_1.BLOCK, constants_1.BLOCK],
    ],
    [
        [COLOR3, COLOR3, COLOR3],
        [COLOR3, constants_1.BLOCK, constants_1.BLOCK],
        [constants_1.BLOCK, constants_1.BLOCK, constants_1.BLOCK],
    ],
    [
        [COLOR4, COLOR4, COLOR4],
        [constants_1.BLOCK, COLOR4, constants_1.BLOCK],
        [constants_1.BLOCK, constants_1.BLOCK, constants_1.BLOCK],
    ],
    [
        [constants_1.BLOCK, constants_1.BLOCK, COLOR7],
        [constants_1.BLOCK, COLOR7, COLOR7],
        [constants_1.BLOCK, COLOR7, constants_1.BLOCK],
    ],
    // [
    //     [COLOR6, BLOCK, BLOCK],
    //     [COLOR6, COLOR6, BLOCK],
    //     [BLOCK, COLOR6, BLOCK],
    // ],
    [
        [constants_1.BLOCK, COLOR6, COLOR6],
        [COLOR6, COLOR6, constants_1.BLOCK],
        [constants_1.BLOCK, constants_1.BLOCK, constants_1.BLOCK],
    ],
    [
        [COLOR5, COLOR5],
        [COLOR5, COLOR5]
    ],
];
const brickWidth = (brick) => {
    const bricks = new Array(brick[0].length);
    brick.forEach((r) => {
        r.forEach((c, j) => {
            if ([constants_1.EMPTY, constants_1.BLOCK].indexOf(c) == -1)
                bricks[j] = c;
        });
    });
    return bricks.filter((c) => [constants_1.EMPTY, constants_1.BLOCK, undefined].indexOf(c) == -1).length;
};
exports.brickWidth = brickWidth;
const isBreak = (c) => [COLOR1, COLOR2, COLOR3, COLOR4, COLOR5, COLOR6, COLOR7].indexOf(c) > -1;
exports.isBreak = isBreak;
const randomBrick = () => {
    let brick = bricks[Math.floor(Math.random() * bricks.length)];
    let steps = Math.floor(Math.random() * 3);
    while (steps > 0) {
        const rotatedBrick = (0, rotation_1.emptyBrick)(brick);
        brick.forEach((r, i) => r.forEach((c, j) => {
            rotatedBrick[j][brick[0].length - 1 - i] = c;
        }));
        brick = rotatedBrick;
        steps -= 1;
    }
    return brick;
};
exports.randomBrick = randomBrick;
const clearGame = () => Array(constants_1.GAME_SIZE_HEIGHT).fill(constants_1.EMPTY).map(e => Array(constants_1.GAME_SIZE_WEIGHT).fill(constants_1.EMPTY));
exports.clearGame = clearGame;
const updatePosition = (position, column) => [constants_1.EMPTY, constants_1.BLOCK, constants_1.SHADOW].indexOf(position) != -1 ? column : position;
exports.updatePosition = updatePosition;
const validGame = (game) => game.map(r => {
    return r.filter((_, i) => i < constants_1.GAME_SIZE_HEIGHT).filter((_, i) => i < constants_1.GAME_SIZE_WEIGHT);
});
exports.validGame = validGame;
const validBrick = (brick) => brick.filter(e => e.some(b => [constants_1.EMPTY, constants_1.BLOCK].indexOf(b) == -1));
exports.validBrick = validBrick;
const score = (state) => {
    let scoreIndex = state.game.findIndex(e => e.every(e => [constants_1.EMPTY, constants_1.BLOCK].indexOf(e) == -1));
    let score = 0;
    while (scoreIndex > -1) {
        score += 1;
        state.game.splice(scoreIndex, 1);
        state.game = [Array(constants_1.GAME_SIZE_WEIGHT).fill(constants_1.EMPTY), ...state.game];
        scoreIndex = state.game.findIndex(e => e.every(e => [constants_1.EMPTY, constants_1.BLOCK].indexOf(e) == -1));
    }
    if (((state.lines + score) % 10 == 0) && score) {
        state.level = Math.min(state.level + 1, 15);
    }
    state.lines += score;
    switch (score) {
        case (1):
            state.score += 10;
            break;
        case (2):
            state.score += 30;
            break;
        case (3):
            state.score += 70;
            break;
        case (4):
            state.score += 150;
            break;
    }
    return [score, state];
};
exports.score = score;
