"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const tetro_1 = require("./lib/tetro");
const rotation_1 = require("./lib/rotation");
const keyboard_1 = require("./lib/keyboard");
const collision_1 = require("./lib/collision");
const render_1 = require("./lib/render");
const constants_1 = require("@games/tetro/src/lib/constants");
const utils_1 = require("@src/shared/utils");
const fps = 30;
const initialSpeed = Math.ceil(fps / constants_1.LEVELS['1']);
const defaultVersion = 1;
const versionInitial = function (value) {
    let numValue = parseInt(value || defaultVersion) || defaultVersion;
    if ([1, 2, 3].indexOf(numValue) < 0) {
        numValue = defaultVersion;
    }
    return numValue;
};
class TetroCtrl {
    constructor($scope, $timeout, $q, $location, ModalServiceFactory, ConfigService, SoundService) {
        var _a;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$q = $q;
        this.$location = $location;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.version = versionInitial(this.$location.search()['version']);
        this.state = new rxjs_1.Subject();
        this.destroy = new rxjs_1.Subject();
        this.pause = true;
        this.nextBrick = (0, tetro_1.randomBrick)();
        this.nextBrick$ = new rxjs_1.BehaviorSubject(this.nextBrick);
        this.score = 0;
        this.bestScore = JSON.parse(localStorage.getItem(`${this.constructor.name}_bestScores`) || '{}')[this.version];
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.startWith = parseInt(localStorage.getItem(`${this.constructor.name}_startWith`) || '0');
        this.timeRemaining = (0, utils_1.formatTimeRemaining)(this.startWith);
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.timerWork = false;
        this.gameFinish = false;
        (_a = this.ConfigService.logoLink$) === null || _a === void 0 ? void 0 : _a.pipe((0, operators_1.tap)((currentTarget) => {
            if (!localStorage.getItem(`${this.constructor.name}_game`)) {
                // event.target?.dispatchEvent(e)
                window.location.href = currentTarget.href;
            }
            else {
                this._confirmNewGame((result) => {
                    if (result) {
                        localStorage.removeItem(`${this.constructor.name}_game`);
                        window.location.href = currentTarget.href;
                    }
                });
            }
        })).subscribe();
    }
    setVersion(version) {
        this.newGame(version);
    }
    setParam(name, value) {
        if (name == 'version') {
            if (value == defaultVersion) {
                this.$location.search('version', null);
            }
            else {
                this.$location.search('version', value);
            }
        }
    }
    setScore(score) {
        const scores = JSON.parse(localStorage.getItem(`${this.constructor.name}_bestScores`) || '{}');
        scores[this.version] = Math.max(scores[this.version] || 0, score);
        this.score = score;
        this.bestScore = scores[this.version];
        localStorage.setItem(`${this.constructor.name}_bestScores`, JSON.stringify(scores));
    }
    $onInit() {
        const keydown$ = (0, rxjs_1.fromEvent)(document, 'keydown').pipe((0, operators_1.filter)(event => !event.repeat), (0, operators_1.share)());
        const keyup$ = (0, rxjs_1.fromEvent)(document, 'keyup').pipe((0, operators_1.filter)(event => !event.repeat), (0, operators_1.share)());
        (0, rxjs_1.fromEvent)(document, 'keydown').pipe((0, operators_1.filter)((e) => {
            return ['ArrowUp', 'ArrowDown', 'Space'].indexOf(e.code) != -1;
        }), (0, operators_1.tap)((e) => {
            e.preventDefault();
        })).subscribe();
        this.nextBrick$.pipe((0, operators_1.tap)((brick) => {
            this.nextBrick = brick;
        })).subscribe();
        const restoreDefaultXSpeed = new rxjs_1.BehaviorSubject(initialSpeed);
        const holdKey = (0, rxjs_1.merge)((0, rxjs_1.fromEvent)(document, 'keydown').pipe((0, operators_1.withLatestFrom)(keydown$), (0, operators_1.filter)(([e1, e2]) => {
            // console.log(e1.timeStamp - e2.timeStamp)
            return (e1.timeStamp - e2.timeStamp) > 300;
        }), (0, operators_1.map)(([e1, e2]) => {
            return e1;
        })), keyup$).pipe((0, operators_1.share)());
        const down = holdKey.pipe((0, operators_1.filter)((e) => {
            return (['ArrowDown', 'KeyS'].indexOf(e.code) != -1);
        }), (0, operators_1.map)((e) => {
            this.pause = false;
            return e;
        }), (0, operators_1.distinctUntilChanged)((x, y) => (x.type == y.type)), (0, operators_1.map)((e) => {
            // console.log(e.type)
            return (e.type == 'keyup') ? initialSpeed : 70;
        }));
        const space = (0, rxjs_1.merge)(keydown$.pipe((0, operators_1.filter)(event => !event.repeat))).pipe((0, operators_1.filter)((e) => {
            return (['Space',].indexOf(e.code) != -1);
        }), (0, operators_1.map)((e) => {
            this.pause = false;
            return e;
        }), (0, operators_1.mapTo)(5));
        const hold$ = new rxjs_1.Subject();
        const player$ = (0, rxjs_1.combineLatest)((0, rxjs_1.of)(null).pipe((0, operators_1.switchMap)(() => {
            const saved = JSON.parse(localStorage.getItem(`${this.constructor.name}_game`) || '{}');
            if (saved.version) {
                this.version = saved.version;
                this.setParam('version', saved.version);
            }
            return (0, rxjs_1.of)(saved.brick ? saved.brick : (0, tetro_1.randomBrick)());
        })), (0, rxjs_1.of)({ code: '' }), (0, rxjs_1.merge)(keyup$, hold$).pipe((0, operators_1.startWith)({ code: undefined }), (0, operators_1.filter)((e) => {
            return ['KeyA', 'KeyD', 'KeyW', 'KeyS', 'ArrowUp',
                'ArrowDown', 'ArrowLeft', 'ArrowRight', "Space", undefined].indexOf(e.code) != -1;
        }), (0, operators_1.pluck)('code'))).pipe((0, operators_1.map)(([brick, key, keyCode]) => {
            console.log('player$', key, keyCode);
            if (keyCode && this.pause)
                this.pause = false;
            key.code = keyCode;
            return [brick, key];
        }), (0, operators_1.takeUntil)(this.destroy));
        const state$ = (0, rxjs_1.combineLatest)(this.state, (0, rxjs_1.merge)(down, space, restoreDefaultXSpeed).pipe((0, operators_1.startWith)(initialSpeed), (0, operators_1.distinctUntilChanged)())).pipe((0, operators_1.switchMap)(([initialState, speed]) => {
            //@ts-ignore
            const timerSpeed = (speed == initialSpeed) ? this.getSpeed(initialState.level) : speed;
            // const timerSpeed = speed
            return (0, rxjs_1.timer)(0, timerSpeed).pipe((0, operators_1.filter)((i) => !this.pause || i == 0), (0, operators_1.map)((i) => {
                return [initialState, i];
            }), (0, operators_1.scan)(([state, i]) => {
                state.x += 1;
                return [state, i];
            }), (0, operators_1.map)(([state, i]) => {
                return state;
            }));
        }), (0, operators_1.share)());
        const progress$ = new rxjs_1.Subject();
        const fastMoveFabric = (keys, offsetFunction) => holdKey.pipe((0, operators_1.filter)((e) => (keys.indexOf(e.code) != -1)), (0, operators_1.tap)((e) => {
            this.pause = false;
            return e;
        }), (0, operators_1.distinctUntilChanged)((x, y) => (x.type == y.type)), (0, operators_1.withLatestFrom)(progress$.pipe((0, operators_1.share)())), (0, operators_1.switchMap)(([e, [state, brick]]) => {
            console.log('fastMoveFabric ', [[e, state], brick]);
            if (e.type == 'keydown') {
                return (0, rxjs_1.timer)(0, 70).pipe((0, operators_1.mapTo)(state), (0, operators_1.scan)((state) => {
                    ((newState, newBrick) => (state = newState))(offsetFunction(state, brick));
                    // offsetFunction(state, brick)
                    return state;
                }), 
                // takeWhile(() => state.y >= 0),
                (0, operators_1.takeUntil)(keydown$));
            }
            else {
                return (0, rxjs_1.of)(state);
            }
        }));
        this.game$ = (0, rxjs_1.combineLatest)((0, rxjs_1.merge)(fastMoveFabric(['ArrowLeft', 'KeyA'], (state, brick) => hold$.next({ code: 'ArrowLeft' })), fastMoveFabric(['ArrowRight', 'KeyD'], (state, brick) => hold$.next({ code: 'ArrowRight' })), fastMoveFabric(['ArrowUp'], (state, brick) => hold$.next({ code: 'ArrowUp' })), state$), player$).pipe(
        // tap((e) => console.log('pre scan', e)),
        (0, operators_1.scan)(([state, [brick, key]]) => {
            this._startTimer.next(true);
            // key.code = 'ArrowUp'
            // console.log('state', JSON.stringify(key))
            // console.log('state brick', JSON.stringify(brick))
            // console.log('scan')
            if (state.x + (0, tetro_1.validBrick)(brick).length > constants_1.GAME_SIZE_HEIGHT) {
                // console.log('scan -> collideBrick')
                // state.level += 1
                this.SoundService.play('collision');
                restoreDefaultXSpeed.next(initialSpeed);
                state = (0, collision_1.collideBrick)(state, brick, true);
                brick = this.nextBrick;
                this.nextBrick$.next((0, tetro_1.randomBrick)());
            }
            // console.log(key)
            // state = handleKeyPress(state, brick, key);
            ((newState) => (state = newState))((0, keyboard_1.handleKeyPress)(state, brick, key));
            (([doRotate, newState, rotatedBrick]) => (state = newState,
                brick = rotatedBrick,
                doRotate ? this.SoundService.play('rotate') : null))((0, rotation_1.rotate)(state, brick, key));
            (([newState, collidedBrick, isGoingToCollide]) => {
                state = newState;
                if (isGoingToCollide) {
                    this.SoundService.play('collision');
                    // state.level += 1
                    brick = this.nextBrick;
                    this.nextBrick$.next((0, tetro_1.randomBrick)());
                    restoreDefaultXSpeed.next(initialSpeed);
                }
            })((0, collision_1.collide)(state, brick));
            (([score, _]) => {
                if (score > 0) {
                    this.SoundService.play('line');
                }
            })((0, tetro_1.score)(state));
            (0, keyboard_1.resetKey)(key);
            localStorage.setItem(`${this.constructor.name}_game`, JSON.stringify({
                version: this.version,
                state,
                brick,
            }));
            // console.log('end state', state.x)
            progress$.next([state, brick]);
            return [state, [brick, key]];
        }), (0, operators_1.takeWhile)(([state, [brick, key]]) => {
            // по центу первый новый элемент
            if (!state.x && (state.y == 5) && state.newRound) {
                state.y -= (0, keyboard_1.leftOffset)(brick) + Math.ceil((0, tetro_1.brickWidth)(brick) / 2);
                state.newRound = false;
            }
            const brickOn1Line = !state.game[1].some(c => [constants_1.EMPTY, constants_1.BLOCK].indexOf(c) == -1);
            this.$scope.$apply(() => {
                this.s = state;
                this.setScore(state.score);
            });
            if (!brickOn1Line && (0, collision_1.areAnyBricksColliding)(state, brick)) {
                let i = 1;
                const lastBrick = [];
                while (!(0, collision_1.areAnyBricksColliding)(state, [brick[brick.length - i], ...lastBrick]) && (i <= brick.length)) {
                    lastBrick.unshift(brick[brick.length - i]);
                    i += 1;
                }
                // console.log(
                //     {lastBrick}
                // )
                // lastBrick.splice(1, lastBrick.length)
                (0, render_1.render)(state, lastBrick);
                this.endGame();
                return false;
            }
            (0, render_1.render)(state, brick);
            return true;
        }), 
        // tap(([state, [brick, key]]) => {
        //     // по центу первый новый элемент
        //     if (!state.x && (state.y == 5) && state.newRound) {
        //         state.y -= leftOffset(brick) + Math.ceil(brickWidth(brick) / 2)
        //         state.newRound = false
        //     }
        //
        //     render(state, brick)
        //
        //
        // }),
        (0, operators_1.takeUntil)(this.destroy), (0, operators_1.catchError)((err) => {
            console.error(err);
            this.endGame();
            return rxjs_1.NEVER;
        }), (0, operators_1.finalize)(() => {
            this.gameFinish = true;
            console.log('finalize game$');
        }));
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            // console.log('_startTimer', value)
            if (!value) {
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(this.startWith);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return rxjs_1.EMPTY;
        })).subscribe();
        this.$timeout(() => {
            var _a, _b;
            (_a = this.game$) === null || _a === void 0 ? void 0 : _a.subscribe();
            const saved = JSON.parse(localStorage.getItem(`${this.constructor.name}_game`) || '{}');
            if (saved.version) {
                this.version = saved.version;
                this.setParam('version', saved.version);
            }
            (_b = this.state) === null || _b === void 0 ? void 0 : _b.next(saved.state ? saved.state : this.getInitial());
        });
    }
    getInitial() {
        return {
            game: (0, tetro_1.clearGame)(),
            x: 0,
            y: (constants_1.GAME_SIZE_WEIGHT / 2),
            lines: 0,
            score: 0,
            level: 1,
            newRound: true
        };
    }
    getSpeed(level) {
        //@ts-ignore
        return Math.ceil(fps / constants_1.LEVELS[`${level}`]);
    }
    newGame(version) {
        this._confirmNewGame((result) => {
            var _a, _b;
            if (result) {
                this.version = version || this.version;
                this.setParam('version', this.version);
                localStorage.removeItem(`${this.constructor.name}_game`);
                localStorage.removeItem(`${this.constructor.name}_startWith`);
                this.destroy.next();
                this.pause = true;
                (_a = this.game$) === null || _a === void 0 ? void 0 : _a.subscribe();
                this.nextBrick$.next((0, tetro_1.randomBrick)());
                (_b = this.state) === null || _b === void 0 ? void 0 : _b.next(this.getInitial());
                this.bestScore = JSON.parse(localStorage.getItem(`${this.constructor.name}_bestScores`) || '{}')[`${this.version}`];
                this.score = 0;
                this._startTimer.next(null);
                this.startWith = 0;
                this.timeRemaining = '00:00';
                this.gameFinish = false;
            }
        });
    }
    pauseGame() {
        if (!this.gameFinish && this.ConfigService.cookieSettings.show_timer) {
            this.pause = true;
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "pause-comp",
                scope: this.$scope,
                strategy: "if_close_all"
            }).then(() => {
                this.pause = false;
                this._resume.next('visible');
            });
        }
    }
    validBrick(brick) {
        return (0, tetro_1.validBrick)(brick);
    }
    endGame() {
        this._stopTimer.next(null);
        this.SoundService.play('lose');
        localStorage.removeItem(`${this.constructor.name}_game`);
        localStorage.removeItem(`${this.constructor.name}_startWith`);
        this.ModalServiceFactory.open({
            id: 'game_status',
            template: require("./end_game.ng.html"),
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                timeRemaining: this.timeRemaining,
                score: this.score,
                lines: this.s.lines,
                bestScore: this.bestScore,
                level: this.s.level,
                cookieSettings: this.ConfigService.cookieSettings
            }
        }).then((result) => {
            if (result == 'newGame') {
                this.newGame();
            }
        });
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            // console.log(v1, v2)
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            const sec = i + startWith;
            this.$timeout(() => {
                this.timerWork = true;
                this.startWith = sec;
                localStorage.setItem(`${this.constructor.name}_startWith`, sec.toString());
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(sec);
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    _confirmNewGame(callback) {
        if (this.gameFinish || !localStorage.getItem(`${this.constructor.name}_game`)) {
            return this.$q.when().then(callback ? callback(true) : null);
        }
        else {
            const pause = this.pause;
            this.pause = true;
            this._stopTimer.next(true);
            return this.ModalServiceFactory.open({
                id: 'nonogram_new_game',
                component: "confirm-comp",
                scope: this.$scope,
                extraContext: {
                    settings: {}
                }
            }).then((result) => {
                if (result) {
                    callback ? callback(result) : null;
                }
                else {
                    this.pause = pause;
                    if (!pause) {
                        this._startTimer.next(null);
                        this._startTimer.next(true);
                    }
                    throw { error: 'cancel' };
                }
            });
        }
    }
}
TetroCtrl.$inject = ['$scope', '$timeout', '$q', '$location', 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameTetro', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: TetroCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['SoundServiceProvider', 'WsServiceProvider', 'ConfigServiceProvider', (SoundServiceProvider, WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('tetro/');
        SoundServiceProvider.setSound({
            'lose': require('./sounds/lose.mp3').default,
            'line': require('./sounds/win.mp3').default,
            'collision': require('./sounds/flag.mp3').default,
            'rotate': require('./sounds/single_open_cell.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            show_timer: true,
            sound_effects: false,
            show_next: true,
            show_greed: true,
            show_shadow: false,
        });
    }]);
