"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collide = exports.collideBrick = exports.areAnyBricksColliding = void 0;
const constants_1 = require("./constants");
const tetro_1 = require("./tetro");
const isGoingToLevelWithExistingBricks = (state, brick) => {
    const gameHeight = state.game.findIndex(r => r.some(c => [constants_1.EMPTY, constants_1.BLOCK].indexOf(c) == -1));
    const brickBottomX = state.x + brick.length - 1;
    return gameHeight > -1 && brickBottomX + 1 > gameHeight;
};
const areAnyBricksColliding = (state, brick) => (0, tetro_1.validBrick)(brick).some((r, i) => r.some((c, j) => [constants_1.EMPTY, constants_1.BLOCK].indexOf(c) != -1
    ? false
    : ((x, y) => (0, tetro_1.isBreak)(state.game[x][y]) === (0, tetro_1.isBreak)(c))(i + state.x, j + state.y)));
exports.areAnyBricksColliding = areAnyBricksColliding;
const collideBrick = (state, brick, isGoingToCollide) => {
    // console.log('collideBrick')
    const xOffset = isGoingToCollide ? 1 : 0;
    // console.log(xOffset, isGoingToCollide)
    (0, tetro_1.validBrick)(brick).forEach((r, i) => {
        r.forEach((c, j) => {
            if ((i + state.x - xOffset) >= 0)
                state.game[i + state.x - xOffset][j + state.y] = (0, tetro_1.updatePosition)(state.game[i + state.x - xOffset][j + state.y], c);
        });
    });
    state.game = (0, tetro_1.validGame)(state.game);
    state.x = 0;
    state.y = (constants_1.GAME_SIZE_WEIGHT / 2);
    state.newRound = true;
    return state;
};
exports.collideBrick = collideBrick;
const collide = (state, brick) => {
    const isGoingToCollide = isGoingToLevelWithExistingBricks(state, brick) &&
        (0, exports.areAnyBricksColliding)(state, brick);
    // const isOnBottom = state.x + validBrick(brick).length + 1 > GAME_SIZE_HEIGHT;
    // console.log({
    //   isGoingToCollide: isGoingToCollide,
    //   isGoingToLevelWithExistingBricks: isGoingToLevelWithExistingBricks(state, brick),
    //   areAnyBricksColliding: areAnyBricksColliding(state, brick),
    //   isOnBottom: isOnBottom,
    //   h: state.x + validBrick(brick).length
    // })
    // console.log(isGoingToCollide)
    if (isGoingToCollide) {
        state = (0, exports.collideBrick)(state, brick, isGoingToCollide);
        // console.log('user nextBrick break', nextBrick)
        // brick = nextBrick || randomBrick();
    }
    return [state, brick, isGoingToCollide];
};
exports.collide = collide;
